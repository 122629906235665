/* =Main Nav
 ----------------------------------------------------------*/
 
nav.main-nav .menu {
  border-bottom: 5px solid $mdphd-dark-blue;
  a {
    color: $mdphd-dark-blue;
  } 
}

nav.main-nav .level-1.active-trail {
  border-bottom: 5px solid $mdphd-dark-blue;
  a {
    color: $wcm-med-gray;
  }
}

nav.main-nav .level-1.home a {
  background-position: 0 -24px;
}

.front nav.main-nav .level-1.home {
  border-bottom: 5px solid $mdphd-dark-blue;   
}

.front nav.main-nav .level-1.home a {
  background-position: 0 -24px;
}

/* =Breadcrumb Navigation
----------------------------------------------------------*/

.breadcrumb-nav >li:hover {
  background: $mdphd-dark-blue;
}

span.bc-toggle-explore {
  color: $mdphd-dark-blue;
}

.breadcrumb-nav .open {
  background: $mdphd-dark-blue;
}

.breadcrumb-nav .open .dropdown-menu>li>a:hover,
.breadcrumb-nav .open .dropdown-menu>li>a:focus {
  color: $mdphd-dark-blue;
}

/* =Second, Third Level Nav
----------------------------------------------------------*/

#second-level-nav a, #third-level-nav a {
  color: $mdphd-dark-grey;
  &:hover {
    color: $mdphd-dark-grey;
  }
}

//FOOTER NAV
.block-menu-block .content {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
}
