//CTA CONTAINERS
.not-front{
	//BOOTSTRAP GRID OVERRIDE
	.two_col{
		@include breakpoint ($xs){
			.mdphd_top_full_col, .mdphd_bottom_full_col{
				padding: 0px 20px;
			}
		}
		@include breakpoint ($sm){
			.mdphd_top_full_col, .mdphd_bottom_full_col{
				padding: 0px 20px;
			}
		}
		@include breakpoint ($md){
			.mdphd_top_left_col, .mdphd_bottom_left_col{
			padding: 0px 7.5px 0px 20px;
			}

			.mdphd_top_right_col, .mdphd_bottom_right_col{
				padding: 0px 0px 0px 7.5px;
			}

			.mdphd_top_full_col, .mdphd_bottom_full_col{
				padding: 0px 0px 0px 20px;
			}
		}

		@include breakpoint ($lg){
			.mdphd_top_left_col, .mdphd_bottom_left_col{
			padding: 0px 7.5px 0px 20px;
			}

			.mdphd_top_right_col, .mdphd_bottom_right_col{
				padding: 0px 0px 0px 7.5px;
			}
			.mdphd_top_full_col, .mdphd_bottom_full_col{
				padding: 0px 0px 0px 20px;
			}
		}
	}

	hr.cta-separator{
		color: $mdphd-lightest-grey;
		@include breakpoint ($xs){
			width: 100%;
		}

		@include breakpoint ($sm){
			width: 100%;
		}

		@include breakpoint ($md){
			width: 413px;
		}

		@include breakpoint ($lg){
			width: 413px;
		}
	}

	hr.cta-separator_left{
		color: $mdphd-lightest-grey;
	    display: inline-block;
		@include breakpoint ($xs){
			width: 100%;
		}

		@include breakpoint ($sm){
			width: 100%;
		}

		@include breakpoint ($md){
			width: 413px;
		}

		@include breakpoint ($lg){
			width: 413px;
		}
	}

	//FACES
	.view-faces{
		.views-field-field-featured-image{
			padding: 10px 0px;
		}
	}

	//DIRECTORY
	.view-profiles{
		.views-row{
			display: inline-block;
			width: 100%;
			padding-bottom: 25px;

			.views-field-field-profile-image-url{
				float: left;
				width: 136px;
				height: 206px;
				margin: 0px 20px 0px 0px;
				img{
					width: 100%;
					overflow: hidden;
				}
			}

			h3.title{
				color: $mdphd-dark-grey;
				margin: 0px;
			}

			.headshot{
				float: left;
				margin-right: 20px;
			}

			.views-label-field-md-phd-grad-inst{
				font-weight: bold;
			    float: left;
			    padding-right: 5px;
			}

			.views-field-field-md-phd-lab-sponsor-1-link{
    			display: inline-block;
    			padding-right: 10px;
				.field-content{
					&:before{
						content: 'Lab Sponsor:';
						font-weight: bold;
					    padding-right: 10px;
					}
				}
			}

			.views-field-field-md-phd-lab-sponsor-2-link{
				display: inline-block;
				.field-content{
					&:before{
						content: '|';
						padding: 0px 10px 0px 0px;
					}
				}
			}

			.views-field-field-email{
			}
		}
	}

	//FACULTY CALL OUTS
	.tri-i-faculty-container{
		text-align: center;
		padding: 80px 0px;
		ul.tri-i-faculty{
			list-style: none;
			li{
				float: left;
				width: 100%;
				text-align: center;
				padding: 20px 0px;
				img{
					padding-bottom: 30px;
					border-bottom: none;
				}
			}
		}
	}

	//PARTNERSHIPS
	.tri-i-partnership{
		text-align: center;
		display: inline-block;
		width: 100%;
	    padding-top: 20px;
    	margin-top: 20px;
		.partnership-container{
			float: left;
			@include breakpoint ($xs){
				width: 100%;
			}
			@include breakpoint ($sm){
				width: 33.33%;
			}
			@include breakpoint ($md){
				width: 33.33%;
			}
			@include breakpoint ($lg){
				width: 33.33%;
			}
			img{
				padding: 20px 0px;
				border-bottom: none;
			}
		}
	}

	//Links
	.main-content{
	  .btn--wcm{
	  	margin-top: 30px;
	  }

	  .center-col-btn{
	  	text-align: center;
	  	@include breakpoint($xs){
	  		padding: 0px 45px;
	  	}
	  	@include breakpoint($sm){
	  		padding: 0px 45px;
	  	}
	  	@include breakpoint($md){
	  		padding: 0px 30%;
	  	}
	  	@include breakpoint($lg){
	  		padding: 0px 30%;
	  	}

	  	.btn--wcm{
	  		&:hover, &:active, &:focus{
	  			text-decoration: none;
	  		}
	  	}

	  }

	  .field-type-taxonomy-term-reference{
	  	h3.field-label{
	  		margin: 0px;
	  	}
	  }
	}

	.information-column{
	  p{
	    a{
	      color: $mdphd-dark-blue;
	      //text-decoration: none;
	      &:hover{
	        color: $mdphd-orange;
	      }
	    }

	    a, a.external-link{
	      &:after{
	        content: '\e802' !important;
	        color: $mdphd-orange;
	        padding-left: 10px;
	        font-size: 60%;
	        font-family: "fontello";
	      }
	    }
	  }
	}

	//VIVO PUBLICATIONS DISABLED
	/*
	.view-publications{

		.select2-container{
			width: 150px !important;
		}

		h3{
			color: $mdphd-white;
			.date-display-single{
				width: 100%;
			    background-color: $mdphd-light-blue;
			    display: inline-block;
			    padding: 10px;
			    margin-bottom: 25px;
			}
		}

		.views-row{
			padding-bottom: 25px;
		}
	}
	*/

	//BIBLIO PUBLICAITONS
	.view-biblio-views{
		h3{
			padding-bottom: 20px;
		    font-weight: bold;
		    background-color: $wcm-bg-gray;
		    border: 1px solid $wcm-border-gray;
		    padding: 0.5em;
		    margin: 1em 0 1em 0;
		}
		.views-row{
	        margin: 1em 0 1em 0;

		    .biblio-title-vancouver{
		    	font-weight: bold;
		    }
		}

		.views-row-last{
		    border-bottom: none;
		}
	}

	//BIBLIO CONTENT PAGE
	.node-biblio{
		.submitted, .field-name-field-person-type-taxonomy, .biblio_pubmed{
			display: none;
		}

		.table-striped>tbody>tr:nth-child(odd)>td, .table-striped>tbody>tr:nth-child(odd)>th{
			background-color: $mdphd-white;
		}

		.table-hover>tbody>tr:hover>td, .table-hover>tbody>tr:hover>th{
			background-color: $mdphd-white;
		}
	}

	//BIBLIO LIST PAGE
	.view-biblio-views{
		.select2-container{
			width: 145px !important;
		}
	}

	//NEWS LIST VIEW
	.view-news{
		.view-filters{
			.select2-container{
				width: 373px;
			}
			.select2-results__option--highlighted{
				&:hover, &:active, &:focus{
			    	background-color: $mdphd-light-blue !important;
		    	}
			}
		}
		.views-row{
			padding: 0px 0px 40px 0px;
			margin: 40px 0px 0px 0px;
		    border-bottom: solid 1px #ddd;
			.teaser-image{
				img{
					float: right;
					margin: 0px 10px;
				}
			}
			h3.teaser-title{
				margin: 0px;
				a{
					color: $mdphd-dark-blue;

					&:hover, &:active, &:focus, &:visited{
						color: $mdphd-light-blue;
					}
					&:after{
						content: '\e802' !important;
						padding: 3px 0px 0px 10px;
						vertical-align: text-top;
					}
				}
			}
			a.read-more{
				padding: 20px 0px 0px 0px;
	    		display: inline-block;
			}
			a.read-more:after{
				    content: '\e80d';
				    color: #e87722;
				    padding: 5px 0 0 10px;
				    font-size: 60%;
				    vertical-align: top;
				    font-family: "fontello";
				    font-style: normal;
				    font-weight: normal;
				    speak: none;
				    display: inline-block;
				    text-decoration: inherit;
				    text-align: center;
				    font-variant: normal;
				    text-transform: none;
				    line-height: 1em;
				    -webkit-font-smoothing: antialiased;
				    -moz-osx-font-smoothing: grayscale;
				    //text-decoration: none;
				    line-height: 1.5;
			    }
		}
		.views-row-last{
			border-bottom: none;
		}
	}

	//INDIVIDUAL NEWS
	.news-featured-image{
	  	.hero-image img {
			width: auto !important;
		}
	}

	.social-share{
		a{
			&:after{
				content: none !important;
				background: none !important;
			}
		}
	}

  /* =Calendar
  ----------------------------------------------------------*/
  .event-list__toggle-view {
    button.active {
      color: $mdphd-dark-blue;
    }
  }

  .fc-header .fc-button {
    color: $mdphd-light-blue;
  }

  .fc-content {
    .calendar-event {
      a {
        color: $mdphd-dark-blue;
      }
    }
  }

  .event__title a {
    color: $mdphd-dark-blue;
    &:hover {
      color: $mdphd-orange;
    }
  }

	//INTERIOR SECOND LEVEL NAV

	#second-level-nav a:hover:after, #third-level-nav a:hover:after {
	    border: 1px solid $mdphd-orange;
	    color: $mdphd-orange;
	}

	.pane-wcmc-second-level-nav{
	  .level-2{
	    a.wcmc-button{
	      color: $mdphd-dark-grey !important;
	    }
	  }
	}

	//NTTERIOR THIRD LEVEL NAV
	.pane-menu-tree {
	  display: none;
	  @include breakpoint($sm) {
	    display: block;
	    margin: 20px 0;
	  }

  	ul{
		margin: 0;
  		padding: 0;

	  	li{
			list-style: none;

			a {
				display: block;
				position: relative;
				background-color: $wcm-white;
				color: $mdphd-dark-grey !important;
				border-bottom: 1px solid $wcm-border-gray;
				font-size: 13px;
				line-height: 50px;
				text-align: right;
				padding: 0 60px 0 0;
				@include transition(background-color .25s);
				&:after {
					content: '\e802';
					position: absolute;
					right: 10px;
					top: 5px;
					width: 40px;
					height: 40px;
					color: $mdphd-dark-grey;
					border: 1px solid $mdphd-dark-grey;
					border-radius: 50%;
					font-size: 17px;
					text-align: center;
					@include fontello();
					line-height: 42px;
					@include transition(background-color .25s);
			}
			&:hover {
				color: $mdphd-orange;
				background-color: #f7f7f7;
				text-decoration: none;
				border-width: 0 0 1px;
			}

			&:hover:after {
				border: 1px solid $mdphd-orange;
				color: $mdphd-orange;
				}
			}
		}
	}
}

	/*PDF IFRAME*/
	#iframepdf{
		@include breakpoint ($xs){
			width: 100%;
			height: 400px;
		}

		@include breakpoint ($sm){
			width: 100%;
			height: 400px;
		}

		@include breakpoint ($md){
			width: 100%;
			height: 600px;
		}

		@include breakpoint ($lg){
			width: 100%;
			height: 690px;
		}
	}
}

/* =Contact Form
----------------------------------------------------------*/

.webform-client-form-7 .form-select {
  max-width: 600px;
}

