$mdphd-black: #000000;
$mdphd-multiply-dark-blue: #0348a9;
$mdphd-dark-blue: #005ad9;
$mdphd-light-blue: #0075DB;
$mdphd-white: #FFFFFF;
$mdphd-dark-grey: #555555;
$mdphd-medium-grey: #777777;
$mdphd-light-grey: #dddddd;
$mdphd-lightest-grey: #f7f7f7;
$mdphd-orange: #cf4520;
