//FONTELLO IMPORT
@import "../fonts/fontello/css/fontello-embedded";

.front{
	.container-fluid{
		@include breakpoint ($sm){
			padding-left: 0px;
    		padding-right: 0px;
		}

		@include breakpoint ($md){
			padding-left: 0px;
    		padding-right: 0px;
		}
	}

	.homepage_hero{
		padding: 10px 0px 0px 0px;
		position: relative;
	    overflow: hidden;
	    height: auto;
		
		//GRID OVERRIDE
		.homepage_hero_left{
			@include breakpoint($xs){
				padding-right: 0px;
				padding-left: 0px;
			}

			@include breakpoint($sm){
				padding-right: 20px;
    			padding-left: 20px;
			}

			@include breakpoint($md){
				padding-right: 10px;
    			padding-left: 20px;
			}
			@include breakpoint($lg){
				padding-right: 10px;
    			padding-left: 20px;
			}
		}

		.homepage_hero_right{
			@include breakpoint($xs){
				padding-left: 0px;
				padding-right: 0px;
			}

			@include breakpoint($sm){
				padding-right: 20px;
    			padding-left: 20px;
			}

			@include breakpoint($md){
			    padding-right: 20px;
    			padding-left: 10px;
			}
			@include breakpoint($lg){
				padding-right: 20px;
    			padding-left: 10px;
			}

			.homepage_external_buttons{
				.btn--wcm{
					height: 50px;
				    margin: 0px 0px 10px 0px;
				    padding: 10px 0px;
			        width: 100%;
			        
				    &:before{
				    	padding: 5px 0px;
				    }
				}

				.button_left{
					padding-right: 7.5px;			
				}

				.button_right{
					padding-left: 7.5px;
				}
			}
		}
	}

	.homepage_hero_right{
		//SOCIAL MEDIA CTA
		
		ul.footer-contact__social-links{
		    height: 72px;
		    width: 100%;
		    text-align: center;
		    padding: 15px 0px;
		    background-color: $mdphd-lightest-grey;
		    margin-bottom: 10px;

		    li.footer-contact__twitter, li.footer-contact__facebook, li.footer-contact__youtube{
    			width: 24%;
    			height: 42px;
    			border-right: solid 1px $mdphd-light-grey;
	    	}

	    	li.footer-contact__podcast{
    			width: 24%;
    			height: 42px;
	    	}	
		    
	    	a{
		    	color: $mdphd-dark-blue !important;
		    	text-decoration: none;
		    	border-bottom: none;
		    	
		    	&:hover{
		    		color: $mdphd-orange !important;
		    		transition: .5s ease;
		    	}
		    }

		    .footer-contact__podcast a{
			  	&:before{
			    	content: '\f130';
			    	@include fontello();
			  	}
			}
	    }
	}

	//CTA CONTAINERS
	.homepage_mdphd_cta_link{
	  color: $mdphd-black !important;
	  text-decoration: none !important;
	  line-height: 22px;
	  
	  &:hover{
	  	transition: .5s ease;
	    text-decoration: none;
	    &:after{
		    content: '\e802';
		    @include fontello();
		    border-left: solid 1px $mdphd-light-grey;
		    color: $mdphd-orange;
		    float: right;
		    height: 100px;
		    padding: 45px 0px 0px 10px;
		    transition: .5s ease;

		    @include breakpoint ($xs){
		      margin: -130px 25px 0px 0px;
		    }

		    @include breakpoint ($sm){
		      margin: -130px 25px 0px 0px;
		    }

		    @include breakpoint ($md){
		      margin: -140px 25px 0px 0px;
		    }

		    @include breakpoint ($lg){
		      margin: -140px 25px 0px 0px;
		    }
	    }
	  }

	  &:after{
	    content: '\e802';
	    @include fontello();
	    border-left: solid 1px $mdphd-light-grey;
	    color: $mdphd-light-grey;
	    float: right;
	    height: 100px;
	    padding: 45px 0px 0px 10px;

	    @include breakpoint ($xs){
	      margin: -125px 25px 0px 0px;
	    }

	    @include breakpoint ($sm){
	      margin: -125px 25px 0px 0px;
	    }

	    @include breakpoint ($md){
	      margin: -140px 25px 0px 0px;
	    }

	    @include breakpoint ($lg){
	      margin: -140px 25px 0px 0px;
	    }
	  }
	}

	.homepage_mdphd_cta_container{
	  width: 100%;
	  margin-bottom: 10px;
	  overflow: hidden;
	  border-top: solid 1px $mdphd-light-blue;
	  border-left: solid 1px $mdphd-lightest-grey;
	  border-right: solid 1px $mdphd-lightest-grey;
	  border-bottom: solid 1px $mdphd-lightest-grey;
	  background-color: $mdphd-lightest-grey;

	  @include breakpoint ($xs){
	    padding: 10px 60px 20px 23px;
	    height: 135px;
	  }

	  @include breakpoint ($sm){
	    padding: 10px 60px 10px 23px;
	    height: 135px;
	  }

	  @include breakpoint ($md){
	    padding: 10px 60px 40px 23px;
	    height: 162px;
	  }

	  @include breakpoint ($lg){
	    padding: 10px 60px 40px 23px;
	    height: 162px;
	  }

	  &:hover{
	    //background-color: $mdphd-white;
	    transition: .5s ease;
	    border-left: solid 1px $mdphd-lightest-grey;
	    border-right: solid 1px $mdphd-lightest-grey;
	    border-bottom: solid 1px $mdphd-lightest-grey;
	  }

	  h3.title{
	    font-weight: bold;
	  }
	}

	.homepage_mdphd_cta_img{
	  @include mdphd_cta_img();
	  height: 162px;
	  margin-bottom: 10px;

	  img{
	    height: 162px !important;
	  }
	}

	.homepage_cta{
		@include breakpoint ($xs){
			.homepage_col_left, .homepage_col_center, .homepage_col_right{
				padding: 0px;
			}
		}

		@include breakpoint($sm){
			.homepage_col_left, .homepage_col_center, .homepage_col_right{
				padding-right: 20px;
    			padding-left: 20px;
			}
		}

		@include breakpoint ($md){
			.homepage_col_left{
				padding: 0px 7.5px 0px 20px;
			}

			.homepage_col_center{
				padding: 0px 7.5px;
			}

			.homepage_col_right{
				padding: 0px 20px 0px 7.5px;
			}
		}

		@include breakpoint ($lg){
			.homepage_col_left{
			padding: 0px 7.5px 0px 20px;
			}

			.homepage_col_center{
				padding: 0px 7.5px;
			}

			.homepage_col_right{
				padding: 0px 20px 0px 7.5px;
			}
		}
	}

	.homepage_col_right{
		h3{
			margin-left: 25px;
		}
	}

	a.mdphd_cta_link{
		color: $mdphd-black;

		&:hover{
			color: $mdphd-black;
		}
	}

	.homepage_cta_img{
		overflow: hidden;
		margin-bottom: 10px;

		img{
		width: 100%;
		object-fit: cover;

			&:hover{
			  transform: scale(1.05);
			  transition: .5s ease;
			  position: relative;
			}
		}

		@include breakpoint ($md){
			height: 304px;
			margin-bottom: 15px;

			img{
				height: 304px;
			}
		}

		@include breakpoint ($lg){
			height: 304px;

			img{
				height: 304px;
			}
		}

		h3{
		    padding: 20px 0px 10px 20px;
		    margin: -57px 0px 0px 0px;
		    color: $mdphd-white;
		    background-color: #005989;
		    mix-blend-mode: hard-light;
		  }
	}
}  