footer{
    color: $mdphd-dark-grey;
}
.footer-contact{
  background-color: $mdphd-dark-blue;
}

.footer-contact__site-name a{
  font-family: "open sans-regular",sans-serif;
  font-weight: bold;
  &:hover{
    color: $mdphd-white;
  }
}

.footer-contact__map-link a{
  color: $mdphd-light-blue;
  background-color: $mdphd-white;
  //border: none;

  @include breakpoint ($xs){
    border-top: solid 1px $mdphd-dark-blue;
    border-bottom: solid 1px $mdphd-dark-blue;
  }

  &:hover{
    background-color: $mdphd-orange;
    color: $mdphd-white;
    transition: .5s ease;
  }
}

.footer-contact__contact-us a{
  font-family: "open sans-regular",sans-serif;
  font-weight: bold;
  color: $mdphd-dark-blue;
  background-color: $mdphd-white;
  //border: none;

  @include breakpoint ($xs){
    border-top: solid 1px $mdphd-dark-blue;
    border-bottom: solid 1px $mdphd-dark-blue;
  }

  &:hover{
    background-color: $mdphd-orange;
    color: $mdphd-white;
    transition: .5s ease;
  }

  &:before{
    color: $mdphd-light-blue;
  }
}

.footer-nav{
  a{
    color: $mdphd-dark-grey;
    &:hover{
      color: $mdphd-orange !important;
    }
  }
}

.footer-contact__social-links{
  a{
    color: $mdphd-white;
    &:hover{
      color: $mdphd-orange;
      transition: .5s ease;
    }
  }

  a.external-link{
    &:after{
      content: none;
    }
  }

  .footer-contact__podcast a{
    &:before{
      content: '\f130';
    }
  }
}

.footer-site-info{
  border-bottom: 5px solid $mdphd-dark-blue;
  border-top: none;
  padding-bottom: 50px;
}

.footer-partnership{
  @include breakpoint ($xs){
    border-top: none;  
  }

  @include breakpoint ($sm){
    border-top: none;  
  }
  

  a.external-link{
    &:hover{
      color: #ffffff;
    }

    &:after{
      content: none;
    }
  }
}

//FOOTER LOGOS
@mixin footer-partnerships(){
  display: inline-block;
  float: left;
  width: 30%;
  height: 100px;
  @include breakpoint($xs){
    display: block;
    float: none;
    width: auto;
    margin-left: 5%;
    margin-right: 5%;
  }

  &a:hover{
    //text-decoration: none;
  }
}

.footer-partnership__ru{
  @include footer-partnerships();
  background: url(../images/logos/small_RU_logo.png) no-repeat center;
}

.footer-partnership__wcm{
  @include footer-partnerships();
  background: url(../images/logos/small_WCM_logo.png) no-repeat center;
}

.footer-partnership__mskcc{
  @include footer-partnerships();
  background: url(../images/logos/small_MSKCC_logo.png) no-repeat center;
}