//LOGO
.brand__lockup img {
    height: 84px;
}

//SEARCH OVERRIDE
.global-search{
  .search-button-span{
    background: $mdphd-light-blue;
    border: 2px solid $mdphd-light-blue;

    .btn:hover{
      background-color: none;
      border-color: $mdphd-light-blue;
    }
  }

  .global-search-input::placeholder{
    color: $mdphd-dark-blue;
  }

  .form-radios{
    border-top: 1px solid $mdphd-light-blue;
    display: none;
  }
}

.search-toggle{
  .search-toggle-button{
    border: 2px solid $mdphd-light-blue;
  }
  .search-toggle-button:hover .cta-icon-search{
    color: $mdphd-dark-blue;
    fill: $mdphd-light-blue;
  }
}

.search-toggle.search-toggle.open{
  .search-toggle-button{
    background: $mdphd-dark-blue;
    border: 2px solid $mdphd-dark-blue;
  }

  .cta-icon-close{
    fill: $mdphd-light-blue;
  }
}

//HEADER
.brand__nav{
  background-color: $mdphd-light-blue;
  margin: 0px;
}

.brand__nav > .wrap {
  background: $mdphd-dark-blue;
}

.brand{
  .brand__logo a {
    display: none;
  }

  .brand__lockup{
    a {
      color: $mdphd-dark-blue;
    }
    .logo{
      display: inline-block;
      float: left;
      margin: -60px -35px -80px 0px;
      height: 290px;
    }

    h2.header-title{
      display: inline-block;
      float: left;
      color: $mdphd-white;
      margin: 50px 0px;
    }
  }
}

.title-bar{
  background: $mdphd-dark-blue;

  &:hover{
    background: $mdphd-light-blue;
  }
}

.menu-button{
  color: $mdphd-white;
}

/* =Styles for New Nav/Theme
----------------------------------------------------------*/

#skip-link a {
  background: $mdphd-dark-blue;
  &:focus {
    outline-color: $mdphd-light-blue;
  }
}

.main-menu-toggle {
  border-right: 1px solid $mdphd-light-blue;
  &:hover, &:focus {
    @include breakpoint($md) {
      background: $mdphd-light-blue;
    }
  }
}

.main-menu-toggle.active {
  &:focus {
    background: $mdphd-light-blue;
  }
}

.wcm-global-links {
  li {
    &:hover {
      @include breakpoint($md) {
        background: $mdphd-light-blue;
      }
    }
    a {
      border-right: 1px solid $mdphd-light-blue;
      &:focus {
         @include breakpoint($md) {
          background: $mdphd-light-blue;
        }
      }
    }
  }
}

.wcm-global-links li.first a {
  border-left: none;
}

.search-trigger .search-trigger-button {
  border-left: 1px solid $mdphd-light-blue;
  @include breakpoint($md) {
    border-left: none;
  }
}

.search-trigger .search-trigger-button:hover,
.search-trigger:focus {
  @include breakpoint($md) {
    background-color: $mdphd-light-blue;
  }
}

.search-trigger.open .search-trigger-button {
  &:hover {
    @include breakpoint($md) {
     background: $mdphd-light-blue; 
    }
  }
}

