//BREADCRUMBS
.breadcrumb{
    color: $mdphd-dark-grey;
    a{
      color: $mdphd-dark-grey;
    }
}

//HEADER TAGS OVERRIDE
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  color: $mdphd-dark-blue;
  font-family: "open sans-regular",sans-serif;
  font-weight: bold;
}

//BUTTON OVERRIDE
.btn--wcm{
    height: 50px;
    margin: 10px auto;
    padding: 10px 0px;
    border-radius: 0px;
    color: $mdphd-light-blue;
    border-color: $mdphd-light-blue;
    font-weight: bold;
    font-size: 13px;

    &:before{
      content: '\e802' !important;
      color: $mdphd-orange;
      left: 83%;
      padding: 5px 0px;
      opacity: 1;
    }

    &:hover, &:active, &:focus{
      color: $mdphd-orange !important;
      background-color: $mdphd-white;
      border-color: $mdphd-orange !important;
      text-decoration: none;
      transition: .5s ease;

      &:before{
        left: 83%;
      }
    }
}

.site-main{
  .btn-primary, .btn-default{
    background-color: $mdphd-dark-blue;
    border-color: $mdphd-dark-blue;
    margin-top: 35px !important;
  }  
}


//LINK OVERRIDE
.no_link_decoration{
    border-bottom: none;
    &:hover{
      text-decoration: none;
      border-bottom: none;
    }
}

//SHOW HIDE
.element__show-hide{
  .element__toggle{
    color: $mdphd-black;
      &:hover{
        color: $mdphd-light-blue;
      }

      &:after{
        color: $mdphd-light-blue;
        
        &:hover{
         color: $mdphd-light-blue;
        }          
      }
  }
}

//PAGINATION

.pagination>.disabled>span, .pagination>.disabled>span:hover, .pagination>.disabled>span:focus, .pagination>.disabled>a, .pagination>.disabled>a:hover, .pagination>.disabled>a:focus{
  color: $mdphd-dark-blue;
}


.pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus, .pagination>.active>span, .pagination>.active>span:hover, .pagination>.active>span:focus{
    background-color: $mdphd-dark-blue;
    border-color: $mdphd-dark-blue;
}

ul.pagination>li>a{
  color: $mdphd-dark-blue;
    &:hover{
      color: $mdphd-dark-blue;
      border: 1px solid #ddd;
      background-color: $mdphd-white;
    }
}

.pagination>li>a:hover, .pagination>li>a:focus, .pagination>li>span:hover, .pagination>li>span:focus{
    background-color: $mdphd-white;
}

//Selector Buttons and Arrows
.select2-container--default{
  .select2-selection--single{
    .select2-selection__arrow:after{
      color: $mdphd-light-blue;
    }
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $mdphd-light-blue !important;
}

.form-control:focus{
  border-color: $mdphd-light-blue;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(44,158,255,0.6)
}

:focus{
  outline: $mdphd-light-blue auto 5px;
}

//CONTACT
.site-main{
  //PDF Icon
  img.file-icon{
    display: none;
  }
  @mixin icon_override(){
    background-position: center left;
    background-repeat: no-repeat;
    padding: 5px 0px 5px 35px;
    object-fit: margin;
    background-size: 25px;

  }
  a[href*=".pdf"]{
    @include icon_override();
    background-image: url('/sites/all/themes/mdphd_subtheme/images/misc/pdf.svg');
  }

  a{
    color: $mdphd-dark-blue;
    &:active, &:focus, &:visited{
      transition: .5s ease;
    }

    &:hover{
      color: $mdphd-orange;
      transition: .5s ease;
    }
  }

  a.external-link{
    color: $mdphd-dark-blue !important;
    background-color: $mdphd-white !important;
    &:after{
      content: '';
      background-image: url("/sites/all/themes/mdphd_subtheme/images/misc/external-link.svg");
      background-size: cover;
      height: 12px;
      width: 12px;
      margin: 0px 0px 0px 5px;
    }
  }
}

//WEBFORM
.webform-submit{
  color: #fff;
  background-color: $mdphd-dark-blue;
  border-color: $mdphd-light-blue;

  &:hover, &:active, &:focus{
    color: #fff;
    background-color: $mdphd-orange;
    border-color: $mdphd-orange;
  }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23005ad9'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

//SIDEBAR
.information-sidebar{
  float: left;
  @include breakpoint ($xs){
    width: 100% !important;
  }
  @include breakpoint ($sm){
    width: 100% !important;
  }
  @include breakpoint ($md){
    width: 100% !important;
  }
  @include breakpoint ($lg){
    width: auto !important;
  }

  .pane-wcm-cta{
    .wcm-cta__tile .cta-icon{
      color: $mdphd-dark-blue;
      fill: $mdphd-light-blue;
    }

    .wcm-cta{
      a{
        color: $mdphd-dark-blue;
        &:hover{
          color: $mdphd-light-blue;
        }
      }
      .external-link{
        &:after{
          content: none;
        }
      }
    }
  }

  //FACEBOOK INTERNAL SIDEBAR
  .facebook_sidebar{
      text-align: center;
    h3.pane-title{
        text-align: left;
    }
  }

  //FAQ ON SIDEBAR
  .faq-list{
    @include breakpoint ($xs){
      width: 100%;
    }

    @include breakpoint ($sm){
      width: 100%;
    }

    @include breakpoint ($md){
      width: 300px;
    }

    @include breakpoint ($lg){
      width: 300px;
    }
  }

}


//TWITTER
.fb-page{
  ._1drm ._1drn{  
    border: none !important;
  }
  
}

//FILE ICON
.file-icon{
    width: 25px;
    margin-right: 10px;
}

//SIDEBAR
.information-column{
  .contact-info{
    padding-top: 10px;
  }

  //FAQ DRAWER
  .faq_container{
    .wcm-cta__tile{
      border: none;
      background-color: $mdphd-white;
      &:hover{
        border: solid 1px $mdphd-light-grey;
        margin-bottom: 0px;
        box-shadow: 1px 1px 1px 1px $mdphd-light-grey;
      }
      .wcm-cta__detail{
        &:after{
          content: '\e802' !important;
          @include fontello();
          color: $mdphd-orange;
          transform: rotate(90deg);
          font-size: 10px;
        }
        .cta-icon-help{
          margin-top: 20px;
        }
      }
    }
    .element__toggle{
      &:after{
        content: '' !important;
        opacity: 0;
      }
      &:hover, &:active, &:focus{
        border: solid 1px $mdphd-light-grey;
        box-shadow: 1px 1px 1px 1px #ddd;
      }
    }

    .element__toggle--open{
      border: solid 1px $mdphd-light-grey;
      box-shadow: 1px 1px 1px 1px $mdphd-light-grey;
      transform: scale(1.1);
      .wcm-cta__detail{
        &:after{
          content: '\e802' !important;
          @include fontello();
          color: $mdphd-orange;
          transform: rotate(270deg);
          font-size: 10px;
        }
      }
    }

    ul.element__hide--open{
      float: left;
      display: block;
      width: 225px;
      background: $mdphd-white;
      border: solid 1px $mdphd-light-grey;
      box-shadow: 1px 1px 1px 1px $mdphd-light-grey;
      padding: 10px 0px;
      position: relative;
      
      a.faq_links{
        color: $mdphd-black;
        border-bottom: none;
      }

      li{
        padding: 0px 10px;
        margin: 5px 0px;
        width: 100%;
        display: inline-block;
        
        &:hover, &:active, &:focus{
          background-color: $mdphd-light-blue;
          color: $mdphd-white;
        }
      }
    }
  }

  //JUMP TO TOP
  .jump-to-top>a{
    color: $mdphd-medium-grey;
  }
}

//SOCIAL MEDIA OVERRIDE
.fb_container{
  text-align: center;
  h3.pane-title{
    text-align: left;
  }
}

//COLUMNS FOR TABLES
.column-container{
  width: 100%;
  display: inline-block;
  padding-bottom: 15px;
  .column-container-row{
    width: 100%;
    min-height: 34px;
    display: inline-block;
    padding: 3px 0px;
    .col-header{
      background-color: $mdphd-light-blue;
      width: 100%;
      height: 37px;
      padding: 5px 10px;
      h3{
        color: $mdphd-white;
        margin: 0px;
      }
    }
    .col-left{
      float: left;
      @include breakpoint ($xs){
        width: 100%;
      }
      @include breakpoint ($sm){
        width: 100%;
      }
      @include breakpoint ($md){
        width: 70%;
      }
      @include breakpoint ($lg){
        width: 70%;
      }
    }

    .col-right{
      float: left;
      @include breakpoint ($xs){
        width: 100%;
      }
      @include breakpoint ($sm){
        width: 100%;
      }
      @include breakpoint ($md){
        width: 30%;
      }
      @include breakpoint ($lg){
        width: 30%;
      }
    }
  }

  .odd{
    background-color: $mdphd-white;
  }
  .even{
    background-color: $mdphd-lightest-grey;
  }
}

//CTA CONTAINERS
.mdphd_cta_link_md{
  color: $mdphd-black !important;
  text-decoration: none !important;
  line-height: 22px;
  
  &:hover{
    transition: .5s ease;
    text-decoration: none !important;
    &:after{
      content: '\e802';
      @include fontello();
      border-left: solid 1px $mdphd-light-grey;
      color: $mdphd-orange;
      height: 85px;
      float: right;
      padding: 40px 0px 0px 10px;
      margin: -125px 30px 0px 0px;
      transition: .5s ease;
    }
  }

  &:after{
    content: '\e802';
    @include fontello();
    border-left: solid 1px $mdphd-light-grey;
    color: $mdphd-light-grey;
    height: 85px;
    float: right;
    padding: 40px 0px 0px 10px;
    margin: -125px 30px 0px 0px;
  }
}

.mdphd_cta_link_sm{
  color: $mdphd-black !important;
  text-decoration: none !important;
  line-height: 22px;
  
  &:hover{
    transition: .5s ease;
    text-decoration: none !important;
    &:after{
      content: '\e802';
      @include fontello();
      border-left: solid 1px $mdphd-light-grey;
      color: $mdphd-orange !important;
      height: 85px;
      float: right;
      padding: 40px 0px 0px 10px;
      margin: -125px 30px 0px 0px;
      transition: .5s ease;
    }
  }

  &:after{
    content: '\e802' !important;
    background-image: none !important;
    @include fontello();
    border-left: solid 1px $mdphd-light-grey;
    color: $mdphd-light-grey;
    float: right !important;
    height: 30px !important;
    width: auto !important;
    padding: 8px 0px 0px 15px !important;
    margin: -60px 25px 0px 0px !important;
  }
}

@mixin mdphd_cta_img{
  overflow: hidden;
  width: 100%;
  margin-bottom: 15px;
  border: solid 1px $mdphd-white;

  img{
    width: 100%;
    object-fit: cover;

    &:hover{
      transform: scale(1.05);
      transition: .5s ease;
      position: relative;
    }
  }

  h3{
    padding: 20px 0px 10px 20px;
    margin: -57px 0px 0px 0px;
    color: $mdphd-white;
    background-color: #005989;
    mix-blend-mode: hard-light;
  }
}

.mdphd_cta_img_sm{
  @include mdphd_cta_img();
  height: 177px;

  img{
    height: 177px !important;
  }
}

.mdphd_cta_img_md{
  @include mdphd_cta_img();
  height: 219px;
  img{
    height: 219px !important;
  }
}

.mdphd_cta_container_md{
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  border-top: solid 1px $mdphd-light-blue;
  border-left: solid 1px $mdphd-lightest-grey;
  border-right: solid 1px $mdphd-lightest-grey;
  border-bottom: solid 1px $mdphd-lightest-grey;
  background-color: $mdphd-lightest-grey;
  padding: 10px 60px 10px 23px;
  height: 135px;

  &:hover{
    transition: .5s ease;
    border-left: solid 1px $mdphd-lightest-grey;
    border-right: solid 1px $mdphd-lightest-grey;
    border-bottom: solid 1px $mdphd-lightest-grey;
  }

  h3.title{
    font-weight: bold;
  }
}

.mdphd_cta_container_sm{
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  padding: 0px 60px 40px 23px;
  overflow: hidden;
  background-color: $mdphd-lightest-grey;
  border-top: solid 1px $mdphd-light-blue;
  border-left: solid 1px $mdphd-lightest-grey;
  border-right: solid 1px $mdphd-lightest-grey;
  border-bottom: solid 1px $mdphd-lightest-grey;

  &:hover{
    transition: .5s ease;
    border-left: solid 1px $mdphd-lightest-grey;
    border-right: solid 1px $mdphd-lightest-grey;
    border-bottom: solid 1px $mdphd-lightest-grey;
  }

  h3.title{
    font-weight: bold;
    margin: 20px 0 0 !important;
  }
}

//TILES
.mdphd-cta__tile {
  width: 100%;
  height: 58px;
  float: left;
  clear: both;
  padding: 00px 20px;
  margin-bottom: 15px;
  background-color: $mdphd-lightest-grey;
  border: solid 1px $mdphd-lightest-grey;
  overflow: hidden;

  &:hover{
    transition: .5s ease;
    color: $mdphd-light-blue !important;
    background-color: $mdphd-white;
  }

  &:after{
    background-image: none;
  }
  &::after{
    background-image: none !important;
  }
}

.mdphd-cta__detail {
    width: 100%;
    font-size: 12px;

    .cta-icon {
      float: left;
      width: 35px;
      height: 35px;
      margin: 10px 20px 10px 5px;
      color: #005ad9;
      fill: $mdphd-light-blue;
    }

    h3{
      float: left;
      margin: 20px;
      font-weight: bold;
    }

    a{
      &:after{
        background-image: none !important;
      }
    }
}

.cta-icon-give, .cta-icon-directory

.mdphd-3col{
  @include breakpoint ($md){
    .mdphd_left_col{
      padding-right: 7.5px !important;
    }

    .mdphd_right_col{
      padding-left: 7.5px !important;
    }
  }

  @include breakpoint ($lg){
    .mdphd_left_col{
      padding-right: 7.5px !important;
    }

    .mdphd_right_col{
      padding-left: 7.5px !important;
    }
  }
}

//SECOND LEVEL NAV
.pane-wcmc-second-level-nav{
  .level-2{
    a.wcmc-button{
      color: $mdphd-light-blue !important;
    }
  }
}